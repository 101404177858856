import {publicLayout} from "sources/entries/publicLayout";
import "./notFound404Page.scss";
import {debounce} from "../../helpers/Other/debounce";

// console.log("about page");
publicLayout.register();
//
// let mouseX = 0;
// let mouseY = 0;
//
// let time = null;
// let degrees = 0;
// let pointer = null;
// const rotateImg = () => {
//     pointer.style.transform = 'rotate(' + degrees + 'deg)';
//     clearTimeout(time);
// }

// document.addEventListener("DOMContentLoaded", function () {
//     pointer = document.querySelector(".js-rotateOnMouse");
//     let pointerBox = pointer.getBoundingClientRect();
//     let centerPoint = window.getComputedStyle(pointer).transformOrigin;
//     let centers = centerPoint.split(" ");
//
//     function rotatePointer(e) {
//         var pointerEvent = e;
//         if (e.targetTouches && e.targetTouches[0]) {
//             e.preventDefault();
//             pointerEvent = e.targetTouches[0];
//             mouseX = pointerEvent.pageX;
//             mouseY = pointerEvent.pageY;
//         } else {
//             mouseX = e.clientX;
//             mouseY = e.clientY;
//         }
//
//         var centerY = pointerBox.top + parseInt(centers[1]) - window.scrollY;
//         var centerX = pointerBox.left + parseInt(centers[0]) - window.scrollX;
//         let radians = Math.atan2(mouseX - centerX, mouseY - centerY);
//         degrees = (radians * (180 / Math.PI) * -1) + 0;
//
//         const db = debounce(rotateImg, 1200, false);
//         db();
//     }
//
//     window.addEventListener('mousemove', rotatePointer);
//     window.addEventListener('touchmove', rotatePointer);
//     window.addEventListener('touchstart', rotatePointer);
// });